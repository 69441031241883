import React from "react"

import classNames from "classnames"

import classes from "./Hamburger.module.scss"

const Hamburger = ({ clicked, active, color = "inherit" }) => {
  return (
    <button
      className={classNames(classes.hamburger, { [classes.isActive]: active })}
      type="button"
      onClick={clicked}
      style={{ color }}
    >
      <span className={classes["hamburger-box"]}>
        <span className={classes["hamburger-inner"]}></span>
      </span>
    </button>
  )
}

export default Hamburger
