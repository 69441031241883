import React, { useEffect, useRef, useState } from "react"
import emailjs from "emailjs-com"
import Backdrop from "../../General/Backdrop/Backdrop"
import Flourish from "../../../res/Flourish"
import PineCircle from "../../General/PineCircle/PineCircle"
import Button from "../../General/Buttons/STDButton/STDButton"
import Cross from "../../../res/svg/Cross"
import FB from "../../../res/svg/FacebookSVG"
import IG from "../../../res/svg/InstagramSVG"
import {
  lockUnderScroll,
  unlockUnderScroll,
} from "../../../Helper Functions/underScroll"
import useWindowSize from "../../../Hooks/useWindowSize"
import classnames from "classnames"
import classes from "./PricingModal.module.scss"
import { EMAIL_JS_USER } from "../../../sensitive/keys"

const PricingModal = ({ history }) => {
  const UNMOUNT_TIME = 110
  const MOBILE_BREAK = 700

  const [unmounting, setUnmounting] = useState(false)

  const modalRef = useRef(null)

  useEffect(() => {
    lockUnderScroll()
    return () => unlockUnderScroll()
  }, [])

  function unmount() {
    setUnmounting(true)
    setTimeout(() => {
      history.push("/")
    }, UNMOUNT_TIME)
  }

  const windowSize = useWindowSize()
  let color = "white"
  let btnWidth = "40%"

  if (windowSize.width <= MOBILE_BREAK) {
    color = "currentColor"
    btnWidth = "100%"
  }

  //For email form

  const [sendable, setSendable] = useState(false)
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })

  function sendEmail(e) {
    e.preventDefault()
    if (sending || sent || !sendable) return -1

    setSending(true)
    emailjs
      .sendForm("service_uvod78b", "template_q2pr6i1", e.target, EMAIL_JS_USER)
      .then(
        (result) => {
          setSent(true)
          setSendable(false)
        },
        (error) => {
          alert(
            "Sorry! There's something not quite right... Please refresh your browser and try again."
          )
          setSending(false)
        }
      )
  }

  function handleChange(e) {
    function validateForm(data) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return (
        data.name.length > 1 &&
        re.test(data.email.toLowerCase()) &&
        data.message.length > 15
      )
    }

    const data = { ...formData }
    data[e.target.name] = e.target.value
    setFormData(data)
    if (validateForm(data)) {
      setSendable(true)
    } else {
      setSendable(false)
    }
  }

  return (
    <>
      {true && <Backdrop clicked={unmount} />}
      <div className={classes.modalScreen} onClick={unmount}>
        <div
          className={classnames(classes.modal, {
            [classes.unmounting]: unmounting,
          })}
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.modalContent}>
            <div className={classes.crossContainer}>
              <Cross color={color} width="33px" clicked={unmount} />
            </div>
            <div className={classes.sig}>
              <div className={classes.circle}>
                <PineCircle alpha={0.8} />
              </div>
              <h1>SERVICE PRICING</h1>
              <Flourish height="3.3rem" color="currentColor" />
            </div>
            <h2>CONSULTATIONS</h2>
            <div className={classes.divider}></div>

            <p>
              Initial Consultation
              <br />
              <span>&#36;150.00</span>
              <br />
              <span className={classes.italic}>
                The initial consultation requires the client to be present for
                1.5hrs unless otherwise arranged.
              </span>
            </p>

            <p>
              Following Consultations
              <br />
              <span>&#36;120.00</span>
              <br />
              <span className={classes.italic}>
                All subsequent consultations are booked in at 50-60mins in
                length.
              </span>
            </p>

            <h2>NATUROPATHIC HERBS &amp; MINERALS</h2>
            <div className={classes.divider}></div>
            <p>
              <span className={classes.italic}>
                All herbs &amp; minerals provided will be charged at an
                individual rate based on what is deemed necessary. The client
                will always be consulted before these are dispensed.
              </span>
            </p>
            <div className={classes.flourishContainer}>
              <Flourish height="3.3rem" color="currentColor" />
            </div>
            <p>
              To book a session with Elizabeth, whether it be in person or via
              distance, please contact her via any of the details below.
              <br />
              <br />
              <a href="tel:0438308727">Phone: +61 438 308 727</a>
              <br />
              <br />
              Contact Form
            </p>
            <form
              className={classes.form}
              id="emailForm"
              onSubmit={sendEmail}
              onChange={handleChange}
            >
              <input
                className={classes.name}
                id="name"
                name="name"
                type="text"
                placeholder="Name"
              />
              <input
                className={classes.email}
                id="email"
                name="email"
                type="text"
                placeholder="Email"
              />
              <input
                className={classes.subject}
                id="subject"
                name="subject"
                type="text"
                placeholder="Subject"
              />
              <textarea
                className={classes.message}
                name="message"
                id="message"
                placeholder="Message"
              ></textarea>
              <Button
                type="submit"
                theme="light"
                style={{ marginLeft: "auto", flex: `0 1 ${btnWidth}` }}
                disabled={sending || !sendable || sent}
              >
                {sent ? "SENT" : "SUBMIT"}
              </Button>
            </form>
            <div className={classes.socials}>
              <IG height="100%" color="currentColor" />
              <FB height="100%" color="currentColor" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PricingModal
