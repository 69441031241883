import React, { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import useWindowSize from "../../../Hooks/useWindowSize"
import Backdrop from "../../General/Backdrop/Backdrop"
import Cross from "../../../res/svg/Cross"
import {
  lockUnderScroll,
  unlockUnderScroll,
} from "../../../Helper Functions/underScroll"
import Player from "react-player/youtube"

import classes from "./VideoModal.module.scss"

const VideoModal = ({ history }) => {
  const UNMOUNT_TIME = 110
  const MOBILE_BREAK = 700

  const [unmounting, setUnmounting] = useState(false)
  const [playing, setPlaying] = useState(false)

  const modalRef = useRef(null)

  useEffect(() => {
    lockUnderScroll()

    // Load Google Player API
    var tag = document.createElement("script")
    tag.src = "https://www.youtube.com/iframe_api"
    var firstScriptTag = document.getElementsByTagName("script")[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    return () => unlockUnderScroll()
  }, [])

  function unmount() {
    setUnmounting(true)
    setTimeout(() => {
      history.push("/videos")
    }, UNMOUNT_TIME)
  }

  // const windowSize = useWindowSize()
  // let color = "white"

  // if (windowSize.width <= MOBILE_BREAK) {
  //   color = "currentColor"
  // }

  function getId() {
    return window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ]
  }

  function play() {
    setPlaying(true)
  }

  return (
    <>
      {true && <Backdrop clicked={unmount} />}
      <div className={classes.modalScreen} onClick={unmount}>
        <div
          className={classnames(classes.modal, {
            [classes.unmounting]: unmounting,
          })}
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.crossContainer}>
            <Cross color="white" width="33px" clicked={unmount} />
          </div>
          {/* <Player url={`https://www.youtube.com/watch?v=${getId()}`} width='100%'/> */}
          <div className={classes.playerWrapper}>
            <Player
              className={classes.reactPlayer}
              url={`https://www.youtube.com/watch?v=${getId()}`}
              width="100%"
              height="100%"
              onReady={play}
              controls={true}
              playing={playing}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoModal
