import React from "react"
import ThresholdReveal from "../../HOCs/ThresholdReveal/ThresholdReveal"
import Button from "../../General/Buttons/STDButton/STDButton"
import { Link } from "react-router-dom"

import img1 from "../../../res/img/products/EGN-04.2008_LabelMock_v3.21.png"
import img2 from "../../../res/img/products/EGN-04.2008_LabelMock_v3.11.png"

import classes from "./Consultation.module.scss"

const Consultation = (props) => {
  return (
    <>
      <ThresholdReveal>
        <h1>CONSULTATIONS</h1>
      </ThresholdReveal>
      <div className={classes.divider}>
        <ThresholdReveal>
          <p>
            Consultations are the primary work done by Libby in person or via
            phone from anywhere in the world... because energy is not confined
            to one particular space and time.
          </p>
          <p>
            Using a variety of techniques that she has acquired over the past
            half a century, her ability to assist her clients with connection to
            self, source and beyond has ensured her consultations have become
            synonymous with expansion and progress for the human experience.
          </p>
        </ThresholdReveal>
      </div>
    </>
  )
}

export default Consultation
