import React from "react"
import PineCircle from "../../../Components/General/PineCircle/PineCircle"
import classes from "./Err404.module.scss"

const Err404 = (props) => {
  return (
    <div className={classes.errorPage}>
      <div>
        <h1>4</h1>
        <div>
          <PineCircle alpha={0.3} />
        </div>
        <h1>4</h1>
      </div>
      <h3>PAGE NOT FOUND</h3>
    </div>
  )
}

export default Err404
