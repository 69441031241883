import React, { useEffect } from "react"
import Nav from "../../General/Nav/Nav"
import ArticlesHeader from "./SecondaryHeader/SecondaryHeader"
import Header from "../../Header/Header"
import Footer from "../../Footer/Footer"
import classes from "./SecondaryLayout.module.scss"
import useWindowSize from "../../../Hooks/useWindowSize"

const Articles = (props) => {
  const HEADER_BP = 550

  useEffect(() => {
    window.document.title = "Elizabeth Gordon | Articles"
    setTimeout(() => window.scrollTo(0, 0), 100)
  }, [])

  return (
    <>
      <div className={classes.layout}>
        <Nav hamburgerColor="var(--white)" />
        {useWindowSize().width > HEADER_BP ? <ArticlesHeader /> : <Header />}
        {props.children}
      </div>
      <Footer />
    </>
  )
}

export default Articles
