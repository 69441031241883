import React, { useEffect, useRef, useState } from "react"
import Backdrop from "../../General/Backdrop/Backdrop"
import Flourish from "../../../res/Flourish"
import PineCircle from "../../General/PineCircle/PineCircle"
import Button from "../../General/Buttons/STDButton/STDButton"
import Cross from "../../../res/svg/Cross"
import {
  lockUnderScroll,
  unlockUnderScroll,
} from "../../../Helper Functions/underScroll"
import useWindowSize from "../../../Hooks/useWindowSize"
import classnames from "classnames"
import classes from "./SoundbedModal.module.scss"

const SoundbedModal = ({ history, goToContact }) => {
  const UNMOUNT_TIME = 110
  const MOBILE_BREAK = 700

  const width = useWindowSize().width

  const [unmounting, setUnmounting] = useState(false)

  const modalRef = useRef(null)

  useEffect(() => {
    lockUnderScroll()
    return () => unlockUnderScroll()
  }, [])

  function unmount() {
    setUnmounting(true)
    setTimeout(() => {
      history.push("/#footer")
    }, UNMOUNT_TIME)
  }

  const windowSize = useWindowSize()
  let color = "white"

  if (windowSize.width <= MOBILE_BREAK) {
    color = "currentColor"
  }

  let btnFontSize = "28px"

  if (width < 468) btnFontSize = "25px"

  return (
    <>
      {<Backdrop clicked={unmount} />}
      {/* {windowSize.width >= MOBILE_BREAK &&
        <Backdrop clicked={toggle} />
      } */}
      <div className={classes.modalScreen} onClick={unmount}>
        <div
          className={classnames(classes.modal, {
            [classes.unmounting]: unmounting,
          })}
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.modalContent}>
            <div className={classes.crossContainer}>
              <Cross color={color} width="33px" clicked={unmount} />
            </div>
            <div className={classes.sig}>
              <div className={classes.circle}>
                <PineCircle alpha={0.8} />
              </div>
              {/* <div className={classes.flourishContainer}>
                <Flourish height="3.3rem" color="currentColor" />
              </div> */}
              <p>
                <b>
                  In this pivotal time it is MORE important for each of us to Be
                  in calmness, centredness and a Higher Knowing as much as
                  possible.
                </b>
                <br />
                <br />I welcome you into this NEW sacred space to provide you
                with an open doorway to be re-uniting yourself with the Greater
                Spiritual Version of your physical body. The opportunity to
                connect with the Pure Higher Frequency of Self is there for you
                in this personal and nurturing environment for those who are
                ready to evolve.
              </p>
              <div className={classes.flourishContainer}>
                <Flourish height="3.3rem" color="currentColor" />
              </div>
            </div>
            <h2>THE HARMONIC SOUND BED</h2>
            <div className={classes.divider}></div>

            <p>
              The Harmonic Sound Bed provides harmonic, balanced stereo sound at
              low frequencies vibrating through the cellular network of the
              body, releasing stress and allowing the cellular and energetic
              systems to remove blocked energies from all areas within you and
              around the Earth.
            </p>

            <p>
              The bed is made of a strong wooden construction to provide the
              best sound resonance, with high density foam for extreme comfort
              and sound penetration. Eight powerful low frequency transducers
              harmonically balanced in stereo, provide the sound frequencies
              along with a range of music therapy in 432hz and 528hz.
            </p>

            <h2>SOUND HEALING</h2>
            <div className={classes.divider}></div>
            <p>
              Sound healing is not new and it was used by most ancient cultures.
            </p>
            <p>
              We are all made of energy and when the body is healthy it
              "vibrates" in a coherent way. Sound healing synchronizes brain
              waves to achieve profound states of relaxation, therefore helping
              to restore the normal vibratory frequencies of the cells in our
              bodies. Sound has the most magical power of restoring order to
              organisms that are malfunctioning.
            </p>
            <p>
              The adult body is 70-80% water, and water is a great conductor for
              sound vibration. Sound Travels 4 times faster through water than
              it does through air.
            </p>
            <p>
              Sound Healing works on the principle that everything is vibration.
              All matter broken down to the sub-atomic level is simply a state
              of vibration and frequency. It is easy to utilise the power of
              this Table in remote sessions ANYWHERE on this planet.
            </p>
            <h2>PHYSICAL BENEFITS OF THE HARMONIC SOUND BED</h2>
            <div className={classes.divider}></div>
            <p>
              You place yourself on the Table in a relaxed environment and your
              safety is paramount. You will not be disturbed at all. Harmonics
              are chosen for you and you lie on the Table between 45 to 55
              minutes and many changes commence to take place whether this be
              the physical, the emotional, mental or spiritual bodies.
            </p>
            <p>
              It is in your highest benefit if you simply ALLOW yourself to
              RECEIVE the many abundance of energies that will be given to you -
              and the ongoing effect continues to take place for the next few
              days. These energies can move through the Conscious mind into the
              Subconscious mind.
            </p>
            <div className={classes.btnContainer}>
              <Button
                clicked={(_) => {
                  unmount()
                  setTimeout(() => goToContact(), 600)
                }}
                style={{ fontSize: btnFontSize }}
              >
                CONTACT FOR A BOOKING
              </Button>
            </div>
            <div className={classes.flourishContainer}>
              <Flourish height="3.3rem" color="currentColor" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SoundbedModal
