import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import Backdrop from "../Backdrop/Backdrop"
import Hamburger from "../Hamburger/Hamburger"
import PineCircle from "../../General/PineCircle/PineCircle"
import useWindowSize from "../../../Hooks/useWindowSize"
import {
  lockUnderScroll,
  unlockUnderScroll,
} from "../../../Helper Functions/underScroll"
import goToRef from "../../../Helper Functions/goToRef"
import classes from "./Nav.module.scss"
import animations from "../../../style/animations/animations.module.scss"
import FacebookSVG from "../../../res/svg/FacebookSVG"
import InstagramSVG from "../../../res/svg/InstagramSVG"

const Nav = ({ refs, hamburgerColor, home }) => {
  const [showNav, setShowNav] = useState(false)
  const [mobileView, setMobileView] = useState(false)
  const [unmounting, setUnmounting] = useState(false)

  const history = useHistory()

  //milliseconds
  const UNMOUNT_TIME = 100
  const SECTION_DELAY = 500

  useEffect(() => () => unlockUnderScroll(), [])

  const goTo = (ref, e) => {
    if (!home) {
      history.push({
        pathname: "/",
        hash: `${ref}`,
      })
    } else {
      e.stopPropagation()
      unmount()
      setTimeout(() => {
        if (ref === "pricing") history.push("/pricing")
        else goToRef(refs[ref + "Ref"])
      }, UNMOUNT_TIME + SECTION_DELAY)
    }
  }

  const toggleNav = () => {
    if (showNav) {
      unlockUnderScroll()
      setUnmounting(false)
    } else {
      lockUnderScroll()
    }
    setShowNav((showNav) => !showNav)
  }

  const unmount = (e = null) => {
    if (e) {
      e.stopPropagation()
    }
    setUnmounting(true)
    setTimeout(toggleNav, UNMOUNT_TIME)
  }

  function goToProducts(e) {
    unmount(e)
  }

  let color = hamburgerColor
  let socialsHeight = "2.6rem"
  let socialsColor = "currentColor"
  let ca = "What is Connected Apothecary?"

  if (useWindowSize().width <= 450) {
    color = showNav ? "currentColor" : hamburgerColor
    ca = "Connected Apothecary"
    socialsHeight = "4rem"
    socialsColor = "var(--pink-main)"
    if (!mobileView) {
      setMobileView(true)
    }
  } else if (mobileView) {
    setMobileView(false)
  }

  const onMenuClick = showNav ? unmount : toggleNav

  return (
    <>
      <div className={classes.hamburgerContainer}>
        <div>
          <Hamburger
            active={showNav}
            clicked={(e) => onMenuClick(e)}
            color={color}
          />
        </div>
      </div>
      {showNav && (
        <>
          <Backdrop unmounting={unmounting} />
          <div className={classes.navScreen} onClick={unmount}>
            {mobileView && (
              <div className={classes.circle}>
                <PineCircle alpha={0.8} />
              </div>
            )}
            <div className={classes.navContainer}>
              <nav className={classes.nav}>
                <li onClick={(e) => goTo("intro", e)}>{ca}</li>
                <li onClick={(e) => goTo("about", e)}>About Elizabeth</li>
                <li onClick={(e) => goTo("approach", e)}>The Approach</li>
                <li onClick={(e) => goTo("services", e)}>Services</li>
                <li onClick={(e) => goTo("consultations", e)}>Consultations</li>
                <li onClick={(e) => goTo("avilability", e)}>Availability</li>
                {/* <li onClick={(e) => goTo("pricing", e)}>Pricing</li>
                <li onClick={(e) => unmount(e)}>
                  <Link to="/testimonials">Testimonials</Link>
                </li>
                <li onClick={(e) => goTo("details", e)}>
                  Contact &amp; Availability
                </li>
                {window.location.href.split("/")[
                  window.location.href.split("/").length - 1
                ] !== "faq" && (
                  <li>
                    <Link to="/faq">FAQ's</Link>
                  </li>
                )} */}
                <div className={classes.divider}></div>
                {!home && (
                  <li
                    className={classes.articleLnk}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Link to="/">Home</Link>
                  </li>
                )}
                {/* <li
                  className={classes.articleLnk}
                  onClick={(e) => goToProducts(e)}
                >
                  <Link to="/essences">Shop Essences</Link>
                </li>
                {!window.location.href.endsWith("/articles") &&
                  !window.location.href.endsWith("/articles/") && (
                    <li
                      className={classes.articleLnk}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Link to="/articles">Articles</Link>
                    </li>
                  )}
                {!window.location.href.includes("/videos") && (
                  <li
                    className={classes.articleLnk}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Link to="/videos">Videos</Link>
                  </li>
                )} */}

                <div className={classes.socials}>
                  <div
                    className={classes.igContainer + " " + animations.fadeIn}
                  >
                    <InstagramSVG height={socialsHeight} color={socialsColor} />
                  </div>
                  <div
                    className={classes.fbContainer + " " + animations.fadeIn}
                  >
                    <FacebookSVG height={socialsHeight} color={socialsColor} />
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </>
      )}
    </>
  )
}

// export default withRouter(Nav)
export default Nav
