import React from "react"

import classes from "./Section.module.scss"

const Section = ({ color = "#FFF", children, self = null, id = "" }) => {
  return (
    <div
      id={id}
      className={classes.section}
      style={{ backgroundColor: color }}
      ref={self}
    >
      <div className={classes.sectionContent}>{children}</div>
    </div>
  )
}

export default Section
