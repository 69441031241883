import React from "react"
import ThresholdReveal from "../../HOCs/ThresholdReveal/ThresholdReveal"
import pinecone from "../../../res/img/EGN_Cone_Large.png"

import classes from "./Approach.module.scss"

const About = (props) => {
  return (
    <>
      <ThresholdReveal>
        <h1>APPROACH</h1>
      </ThresholdReveal>
      <ThresholdReveal>
        <p>
          When consulting with a client, Elizabeth has learned to trust her
          ability to connect to their highest perception and be given the
          information that is required to open their doorway to recognise and
          embrace what has been restricting and influencing their evolution.
        </p>
      </ThresholdReveal>
      <ThresholdReveal>
        <p>
          This may involve their physicality or it may involve an event that
          happened in another timeline that has influenced them to this very
          moment through their emotional or mental bodies and the epigenetics.
        </p>
      </ThresholdReveal>
      <ThresholdReveal>
        <p>
          Everything is always unknown as to how this will eventuate. Yet, with
          divine knowledge, it always unfolds with ease and grace.
        </p>
      </ThresholdReveal>
      <img className={classes.pinecone} src={pinecone} alt="pinecone" />
      <ThresholdReveal>
        <p className={classes.end}>
          In every case, the client holds the power to indicate if the practice
          resonates with them. Therefore making the decisions as to the action
          that follow.
          <br />~<br />
          No diagnostic tools are used.
        </p>
      </ThresholdReveal>
    </>
  )
}

export default About
