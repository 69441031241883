import React from "react"
import classNames from "classnames"

import classes from "./Backdrop.module.scss"

const Backdrop = ({ color = "#000", unmounting }) => {
  return (
    <div
      className={classNames(classes.backdrop, {
        [classes.unmounting]: unmounting,
      })}
    ></div>
  )
}

export default Backdrop
