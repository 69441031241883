import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import slug from "slugify"
import classes from "./ProductSelect.module.scss"
import Loading from "../../../General/Loading/Loading"
import cns from "classnames"

const ProductSelect = (props) => {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const prodArray = []
    const KEY = "247c6928aeab938190b6fb37f5a05b87"
    const PW = "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"

    var headers = new Headers()

    headers.append("Authorization", "Basic " + btoa(KEY + ":" + PW))

    //TODO: UNSAFE OPERATION!! FIX BEFORE PRODUCTION

    fetch(
      `https://cors-anywhere.herokuapp.com/https://shop.elizabethgordon.com.au/admin/api/2020-10/products.json?status=active`,
      { headers: headers }
    )
      .then((res) => {
        if (!res.ok) throw new Error("woopsie daisies")
        setLoading(false)
        return res.json()
      })
      .then((json) => {
        json.products.forEach((prod) => {
          prodArray.push({
            product: prod.title,
            imgSrc: prod.image.src,
            slug: prod.handle,
            status: prod.tags.includes("coming") ? "coming soon" : "active",
          })
        })
        setProducts(prodArray)
      })
      .catch((error) => console.error("there is an error: " + error))
  }, [])

  return (
    <>
      {loading && <Loading subject="PRODUCTS" />}
      {!loading && (
        <>
          <span className={classes.select}>
            Select an Essence to learn more
          </span>
          <div className={classes.productSelect}>
            {products.map((prod) => (
              <div
                className={cns(classes.product, {
                  [classes.cs]: prod.status === "coming soon",
                })}
                key={prod.slug}
              >
                {prod.status !== "coming soon" && (
                  <Link
                    to={
                      "/essences/" +
                      slug(prod.product.toLowerCase(), { strict: true })
                    }
                  >
                    <img src={prod.imgSrc} alt={prod.product} />
                    <span className={classes.view}>View Product</span>
                  </Link>
                )}
                {prod.status === "coming soon" && (
                  <>
                    <img src={prod.imgSrc} alt={prod.product} />
                    <span className={classes.view}>View Product</span>
                  </>
                )}

                <span className={classes.buy}>
                  <a
                    href={
                      "https://shop.elizabethgordon.com.au/products/" +
                      prod.slug
                    }
                  >
                    Buy Now
                  </a>
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default ProductSelect
