import React, { useEffect, useRef, useState } from "react"
import { Route } from "react-router-dom"
import classnames from "classnames"
import useWindowSize from "../../../Hooks/useWindowSize"
import Backdrop from "../../General/Backdrop/Backdrop"
import Flourish from "../../../res/Flourish"
import PineCircle from "../../General/PineCircle/PineCircle"
import Button from "../../General/Buttons/STDButton/STDButton"
import Cross from "../../../res/svg/Cross"
import ProductSelect from "./ProductSelect/ProductSelect"
import Product from "./Product/Product"

import FB from "../../../res/svg/FacebookSVG"
import IG from "../../../res/svg/InstagramSVG"
import {
  lockUnderScroll,
  unlockUnderScroll,
} from "../../../Helper Functions/underScroll"

import classes from "./ProductsModal.module.scss"

const ProductsModal = ({ toggle, top, history }) => {
  const UNMOUNT_TIME = 110
  const MOBILE_BREAK = 700

  const [unmounting, setUnmounting] = useState(false)

  const modalRef = useRef(null)

  useEffect(() => {
    lockUnderScroll()
    return () => unlockUnderScroll()
  }, [])

  function unmount() {
    setUnmounting(true)
    setTimeout(() => {
      history.push("/")
    }, UNMOUNT_TIME)
  }

  const windowSize = useWindowSize()
  let color = "white"

  if (windowSize.width <= MOBILE_BREAK) {
    color = "currentColor"
  }

  return (
    <>
      {true && <Backdrop clicked={unmount} />}
      <div className={classes.modalScreen} onClick={unmount}>
        <div
          className={classnames(classes.modal, {
            [classes.unmounting]: unmounting,
          })}
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.modalContent}>
            <div className={classes.crossContainer}>
              <Cross color={color} width="33px" clicked={unmount} />
            </div>
            <div className={classes.sig}>
              <div className={classes.circle}>
                <PineCircle alpha={0.8} />
              </div>
              <h1>QUANTUM ESSENCES</h1>
              <div className={classes.flourishContainer}>
                <Flourish height="3.3rem" color="currentColor" />
              </div>
            </div>
            <p>
              These Quantum essences came into BEING in 2020 whilst the Earth
              planet was strongly learning how to cope within chaos. Under clear
              and precise instructions from the Cosmic Elders of the Star
              Nations, from the Arcturians and the Galactic Federation of Light,
              I was urged to create new essences. Primarily, they are here to
              ground us in this technology that is oriented for our biophysical
              nature, to identify to the new timeline of 5D and Beyond.
              <br />
              <br />
              For more information, download the Essence PDF that is included
              below each essence description.
            </p>
            <a href="https://shop.elizabethgordon.com.au/collections/all">
              <Button
                style={{
                  width: "100%",
                  marginTop: "7rem",
                  color: "white",
                  fontSize: "24px",
                }}
              >
                ORDER ESSENCES NOW
              </Button>
            </a>
            <div className={classes.divider}></div>

            <Route path="/essences" exact component={ProductSelect} />
            <Route path="/essences/:product" exact component={Product} />
            <div className={classes.divider}></div>
            <div
              className={classes.flourishContainer}
              style={{ paddingTop: "8rem" }}
            >
              <Flourish height="3.3rem" color="currentColor" />
            </div>
            <p className={classes.foot}>
              If you have any queries regarding this product, please feel free
              to contact via the enquiry form which you can access{" "}
              <a href="/#footer" style={{ textDecoration: "underline" }}>
                here.
              </a>
            </p>
            <div className={classes.socials}>
              <IG height="100%" color="currentColor" />
              <FB height="100%" color="currentColor" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductsModal
