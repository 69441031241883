import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import useWindowSize from "../../../Hooks/useWindowSize"
import Backdrop from "../../General/Backdrop/Backdrop"
import Flourish from "../../../res/Flourish"
import PineCircle from "../../General/PineCircle/PineCircle"
import Cross from "../../../res/svg/Cross"
import Loading from "../../General/Loading/Loading"

import FB from "../../../res/svg/FacebookSVG"
import IG from "../../../res/svg/InstagramSVG"
import {
  lockUnderScroll,
  unlockUnderScroll,
} from "../../../Helper Functions/underScroll"

import classes from "./TestimoniesModal.module.scss"
import slugify from "slugify"

const TestimoniesModal = ({ toggle, top, history, location }) => {
  const UNMOUNT_TIME = 110
  const MOBILE_BREAK = 700

  const [unmounting, setUnmounting] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [index, setIndex] = useState(0)
  const [testimonies, setTestimones] = useState([
    { quote: "", name: "", slug: "" },
  ])

  const modalRef = useRef(null)

  useEffect(() => {
    lockUnderScroll()
    fetch("https://liz.queued.com.au/wp-json/wp/v2/pages?slug=testimonials")
      .then((res) => {
        if (res.ok) setLoaded(true)
        return res.json()
      })
      .then((json) => {
        let HTMLString = json[0].content.rendered
        HTMLString = HTMLString.replace(/<br>/g, "<br/>")
        HTMLString = HTMLString.replace(/&nbsp;/g, "")
        HTMLString = "<div>" + HTMLString + "</div>"
        const dom = new DOMParser().parseFromString(HTMLString, "text/xml")
        const qs = dom.querySelectorAll("blockquote")
        const as = dom.querySelectorAll("cite")
        const testArray = []
        for (let i = 0; i < qs.length; i++) {
          testArray[i] = {
            quote: qs[i].innerHTML,
            name: as[i].innerHTML.split(",")[0],
            html: as[i].innerHTML,
            slug: encodeURI(
              slugify(as[i].innerHTML.split(",")[0].toLowerCase())
            ),
          }
        }
        if (
          location.pathname.endsWith("testimonials") ||
          location.pathname.endsWith("testimonials/") ||
          matchURLToIndex(testArray) === -1
        ) {
          history.push("/testimonials/" + testArray[0].slug)
        } else {
          setIndex(matchURLToIndex(testArray))
        }

        setTestimones(testArray)
      })

    return () => unlockUnderScroll()
  }, [])

  useEffect(() => {
    if (testimonies[0].slug) {
      setIndex(matchURLToIndex(testimonies))
    }
  }, [location.pathname, index])

  function unmount() {
    setUnmounting(true)
    setTimeout(() => {
      history.push("/")
    }, UNMOUNT_TIME)
  }

  function matchURLToIndex(arr) {
    const slug =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ]
    if (slug === "testimonials" || !arr[0].name) return
    return arr.findIndex((item) => item.slug === slug)
  }

  function getNav() {
    const obj = {}
    if (!testimonies[0].name)
      return { next: { name: "", slug: "" }, prev: { name: "", slug: "" } }
    obj.next =
      index + 1 >= testimonies.length ? testimonies[0] : testimonies[index + 1]
    obj.prev =
      index - 1 < 0
        ? testimonies[testimonies.length - 1]
        : testimonies[index - 1]
    return obj
  }

  const windowSize = useWindowSize()
  let color = "white"

  if (windowSize.width <= MOBILE_BREAK) {
    color = "currentColor"
  }

  return (
    <>
      {true && <Backdrop clicked={unmount} />}
      <div className={classes.modalScreen} onClick={unmount}>
        <div
          className={classnames(classes.modal, {
            [classes.unmounting]: unmounting,
          })}
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.modalContent}>
            <div className={classes.crossContainer}>
              <Cross color={color} width="33px" clicked={unmount} />
            </div>
            <div className={classes.sig}>
              <div className={classes.circle}>
                <PineCircle alpha={0.8} />
              </div>
              <h1>TESTIMONIALS</h1>
              <div className={classes.flourishContainer}>
                <Flourish height="3.3rem" color="currentColor" />
              </div>
            </div>
            <div className={classes.divider}></div>
            {!loaded && <Loading />}
            {loaded && testimonies[index] && (
              <>
                <span className={classes.quote}>&#8220;</span>
                <div
                  className={classes.quoteText}
                  dangerouslySetInnerHTML={{
                    __html: testimonies[index].quote || "",
                  }}
                ></div>
                <span className={classes.quote + " " + classes.endQuote}>
                  &#8221;
                </span>
                <span
                  className={classes.name}
                  dangerouslySetInnerHTML={{
                    __html: testimonies[index].html,
                  }}
                ></span>
                <div className={classes.nav}>
                  <Link to={"/testimonials/" + getNav().prev.slug}>
                    <div className={classes.back}>
                      {getNav().prev.name.split(" ")[0]}
                    </div>
                  </Link>
                  <Link to={"/testimonials/" + getNav().next.slug}>
                    <div className={classes.forward}>
                      {getNav().next.name.split(" ")[0]}
                    </div>
                  </Link>
                </div>
              </>
            )}
            <div className={classes.divider}></div>
            <div
              className={classes.flourishContainer}
              style={{ paddingTop: "8rem" }}
            >
              <Flourish height="3.3rem" color="currentColor" />
            </div>
            <div className={classes.socials}>
              <IG height="100%" color="currentColor" />
              <FB height="100%" color="currentColor" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestimoniesModal
