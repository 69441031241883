import React from "react"
import Button from "../../General/Buttons/STDButton/STDButton"
import ThresholdReveal from "../../HOCs/ThresholdReveal/ThresholdReveal"
import useWindowSize from "../../../Hooks/useWindowSize"
import { Link } from "react-router-dom"

import classes from "./Services.module.scss"

const Services = () => {
  const width = useWindowSize().width
  let btnFontSize = "28px"

  if (width < 468) btnFontSize = "25px"

  return (
    <>
      {/* <ThresholdReveal>
        <h1>SERVICES</h1>
      </ThresholdReveal>
      <ThresholdReveal>
        <h2>CONSULTATIONS</h2>
        <p>
          Consultations are the primary work done in Clinic or via phone from
          anywhere in the world……. because energy is not confined to one
          particular space and time.
        </p>
      </ThresholdReveal>
      <ThresholdReveal>
        <h2>N.S.T TECHNIQUE</h2>
        <p>
          The N.S.T. Technique (Bowen) is still being utilised in sessional work
          as it has stood the test of time for over 30yrs, to enable the body to
          come back into balance on many levels.
        </p>
      </ThresholdReveal>
      <ThresholdReveal>
        <h2>NATUROPATHIC HERBS &amp; MINERALS</h2>
        <p>
          As an additonal aspect to the consultations, adding naturopathic herbs
          and minerals are often recommended to help support the other
          modalities.
        </p>
        <div className={classes.btnContainer}>
          <Link to="/pricing" style={{ fontSize: btnFontSize }}>
            <Button>CLICK TO VIEW PRICING</Button>
          </Link>
        </div>
      </ThresholdReveal> */}
      <ThresholdReveal>
        <h2 className={classes.finalSection}>HARMONIC SOUND BED</h2>
        <p>
          The Harmonic Sound Bed provides harmonic, balanced stereo sound
          vibrating through the cellular network of the body, releasing stress
          and allowing the cellular and energetic systems to remove blocked
          energies from all areas within you and around the Earth.
        </p>
        <p>
          Sound Healing works on the principle that everything is vibration. All
          matter broken down to the sub-atomic level is simply a state of
          vibration and frequency. It is also easy to utilise the power of this
          Table in remote session ANYWHERE on this planet.
        </p>
        {/* <div className={classes.btnContainer}>
          <Link to="/soundbed" style={{ fontSize: btnFontSize }}>
            <Button>READ MORE</Button>
          </Link>
        </div> */}
      </ThresholdReveal>
    </>
  )
}

export default Services
