import React, { useEffect, useState, useRef } from "react"
import classes from "./TreeLine.module.scss"
import classnames from "classnames"

const TreeLine = ({ loadFull = true }) => {
  const PERSPECTIVE = 12

  const [y, setY] = useState(0)
  const [treeline, setTreeline] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [timeoutID, setTimeoutID] = useState(null)
  const [isVisible, setIsVisible] = useState(null)

  const ref = useRef(null)
  const IDRef = useRef(timeoutID)
  const visRef = useRef(isVisible)

  function updateID(id) {
    IDRef.current = id
    setTimeoutID(id)
  }

  function updateVis(v) {
    visRef.current = v
    setIsVisible(v)
  }

  useEffect(() => {
    function handleScroll() {
      if (visRef.current) {
        setY(ref.current.getBoundingClientRect().y)
      }
      updateID(null)
    }

    function throttle(fn, ms) {
      if (IDRef.current) return

      const to = setTimeout(() => {
        fn()
      }, ms)

      updateID(to)
    }

    const throttledScrollHandler = () => throttle(handleScroll, 20)
    window.addEventListener("scroll", throttledScrollHandler)

    const intersectionOptions = {
      root: null,
    }

    const Observer = new IntersectionObserver((e) => {
      updateVis(e[0].isIntersecting)
    }, intersectionOptions)

    Observer.observe(ref.current)

    return () => window.removeEventListener("scroll", throttledScrollHandler)
  }, [])

  useEffect(() => {
    if (!treeline) {
      import("../../../res/img/EGN_TreeLine_Placeholder.jpg").then((tl) =>
        setTreeline(tl.default)
      )
    }
    if (loadFull) {
      import("../../../res/img/EGN_TreeLine_Large.jpg").then((tl) =>
        setTreeline(tl.default)
      )
    }
  }, [loadFull, treeline])

  function loadHandler() {
    if (treeline.includes("Large")) {
      setLoaded(true)
    }
  }

  const imgY = (y / PERSPECTIVE) * -1 + 100

  return (
    <div className={classes.container} ref={ref}>
      <img
        className={classnames(classes.treeline, { [classes.loaded]: loaded })}
        src={treeline}
        alt="Treeline Paralax"
        style={{ transform: `translate3d(0,calc(-50% + ${imgY}px),0)` }}
        onLoad={loadHandler}
      />
    </div>
  )
}

export default TreeLine
