import React from 'react';

const IgPink = (props) => {
    return (
        <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Element / Social Pink Share</title>
            <desc>Created with Sketch.</desc>
            <defs>
                <polygon id="path-1" points="0 0.0222297297 25.9309333 0.0222297297 25.9309333 20.9526081 0 20.9526081"></polygon>
                <polygon id="path-3" points="0.00706236559 0.0765677419 23.9305806 0.0765677419 23.9305806 24 0.00706236559 24"></polygon>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Articles-Post" transform="translate(-887.000000, -4082.000000)">
                    <g id="Element-/-Social-Pink-Share" transform="translate(886.000000, 4080.000000)">
                        <g id="Connect" transform="translate(1.000000, 2.000000)">
                            <g id="social-icons">
                                <g id="li" transform="translate(0.000000, 0.000000)">
                                    <g id="Group-6">
                                        <g id="Clip-5"></g>
                                        <path d="M23.9305892,24.0000086 L18.9062452,24.0000086 L18.9062452,15.1285247 C18.9062452,13.7903742 17.7395355,12.621686 16.3992344,12.621686 C15.0590194,12.621686 13.8923957,13.7903742 13.8923957,15.1285247 L13.8923957,24.0000086 L8.86805161,24.0000086 L8.86805161,8.93747097 L13.8923957,8.93747097 L13.8923957,10.9468473 C14.7245677,9.60869677 16.5625032,8.60293333 18.0741591,8.60293333 C21.2501591,8.60293333 23.9305892,11.2834495 23.9305892,14.4595355 L23.9305892,24.0000086 Z M3.02203011,6.09609462 C1.34727742,6.09609462 0.00706236559,4.75579355 0.00706236559,3.09144946 C0.00706236559,1.4167828 1.34727742,0.0765677419 3.02203011,0.0765677419 C4.68637419,0.0765677419 6.02658925,1.4167828 6.02658925,3.09144946 C6.02658925,4.75579355 4.68637419,6.09609462 3.02203011,6.09609462 L3.02203011,6.09609462 Z M0.504782796,24.0000086 L5.52904086,24.0000086 L5.52904086,8.93747097 L0.504782796,8.93747097 L0.504782796,24.0000086 Z" id="Fill-4" fill="#ECC0C6" mask="url(#mask-4)"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default IgPink;