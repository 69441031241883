import React, { useState } from "react"
import cn from "classnames"

import classes from "./FAQItem.module.scss"

const FAQItem = (props) => {
  const [open, setOpen] = useState(false)

  function toggle() {
    setOpen((o) => !o)
  }

  return (
    <div className={cn(classes.set, { [classes.open]: open })}>
      <div className={classes.main}>
        <h2
          className={classes.question}
          dangerouslySetInnerHTML={{ __html: props.question }}
        ></h2>
        <button onClick={toggle}>
          <div className={classes.toggle}></div>
        </button>
      </div>
      <p
        className={classes.answer}
        dangerouslySetInnerHTML={{ __html: props.answer }}
      ></p>
    </div>
  )
}

export default FAQItem
