import React, { useState } from "react"
import { Link } from "react-router-dom"
import useWindowSize from "../../Hooks/useWindowSize"
import Flourish from "../../res/Flourish"
import Button from "../General/Buttons/STDButton/STDButton"
import goToRef from "../../Helper Functions/goToRef"
import { withRouter } from "react-router-dom"
import logo from "../../res/img/EGN-FooterIcon.png"
import FB from "../../res/svg/FacebookSVG"
import IG from "../../res/svg/InstagramSVG"
import PineCircle from "../General/PineCircle/PineCircle"
import emailjs from "emailjs-com"
import { EMAIL_JS_USER } from "../../sensitive/keys"

import classes from "./Footer.module.scss"

const Footer = ({ refs = null, history, self }) => {
  const [sendable, setSendable] = useState(false)
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })

  function sendEmail(e) {
    e.preventDefault()
    if (sending || sent || !sendable) return -1

    setSending(true)
    emailjs
      .sendForm("service_uvod78b", "template_q2pr6i1", e.target, EMAIL_JS_USER)
      .then(
        (result) => {
          setSent(true)
          setSendable(false)
        },
        (error) => {
          alert(
            "Sorry! There's something not quite right... Please refresh your browser and try submitting again."
          )
          setSending(false)
        }
      )
  }

  function handleChange(e) {
    function validateForm(data) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return (
        data.name.length > 1 &&
        re.test(data.email.toLowerCase()) &&
        data.message.length > 15
      )
    }

    const data = { ...formData }
    data[e.target.name] = e.target.value
    setFormData(data)
    if (validateForm(data)) {
      setSendable(true)
    } else {
      setSendable(false)
    }
  }

  let buttonStyle = { width: "35%", marginLeft: "auto" }

  if (useWindowSize().width <= 608) {
    buttonStyle = { margin: "0 auto", flex: "1 1" }
  }

  const goTo = (ref, e) => {
    if (!refs) {
      history.push({
        pathname: "/",
        hash: `${ref}`,
      })
    } else {
      e.stopPropagation()
      if (ref === "pricing") history.push("/pricing")
      else goToRef(refs[ref + "Ref"])
    }
  }

  return (
    <div className={classes.footer}>
      <div className={classes.footerContent}>
        <div className={classes.top}>
          <div className={classes.sig}>
            <div className={classes.circle}>
              <PineCircle />
            </div>
            {/* <Flourish color="var(--white)" height="2.5rem" /> */}
          </div>
          <div>
            <p>ELIZABETH GORDON</p>
            <div className={classes.refs}>
              <p>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.google.com/maps/place/117+Station+St,+Malvern+VIC+3144/@-37.8677411,145.0297195,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad669e94686226f:0xb7bc163dbd90ec37!8m2!3d-37.8677411!4d145.0319082"
                >
                  Libby is contactable via the form below or more directly by
                  phone on
                  <a href="tel:+61438308727">+61 438 308 727</a>
                </a>
              </p>

              <p>Caulfield South VIC Australia</p>
            </div>
          </div>
        </div>
        <div className={classes.hrline}></div>
        <form
          className={classes.form}
          id="emailForm"
          onSubmit={sendEmail}
          onChange={handleChange}
        >
          <h3 ref={self}>CONTACT</h3>
          <input
            className={classes.name}
            id="name"
            name="name"
            type="text"
            placeholder="Name"
          />
          <input
            className={classes.email}
            id="email"
            name="email"
            type="text"
            placeholder="Email"
          />
          <input
            className={classes.subject}
            id="subject"
            name="subject"
            type="text"
            placeholder="Subject"
          />
          <textarea
            className={classes.message}
            name="message"
            id="message"
            placeholder="Message"
          ></textarea>
          <Button
            type="submit"
            theme="light"
            style={buttonStyle}
            disabled={sending || !sendable || sent}
          >
            {sent ? "SENT" : "SUBMIT"}
          </Button>
        </form>
        <div className={classes.socials}>
          <IG height="100%" color="currentColor" />
          <FB height="100%" color="currentColor" />
        </div>
        {/* <div className={classes.divider}></div>
        <a
          href="/"
          rel="noopener noreferrer"
          target="_blank"
          className={classes.logoWrapper}
        >
          <img className={classes.LFLogo} src={logo} alt="Liforce_Logo" />
        </a>
        <div className={classes.refs}>
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.google.com/maps/place/117+Station+St,+Malvern+VIC+3144/@-37.8677411,145.0297195,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad669e94686226f:0xb7bc163dbd90ec37!8m2!3d-37.8677411!4d145.0319082"
            >
              Located in the Caulfield Area.
              <br /> Please contact Libby directly for location details.
            </a>
          </p>
          <p>
            <a href="tel:+610438308727">0438 308 727</a>
          </p>
          <p>
            <a
              href="mailto:contact@elizabethgordon.com.au "
              rel="noopener noreferrer"
              target="_blank"
            >
              contact@elizabethgordon.com.au
            </a>
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default withRouter(Footer)
