import React, { useEffect, useState, useRef } from "react"
import { Link, withRouter } from "react-router-dom"
import classes from "./ArticleLink.module.scss"

const ArticleLink = ({ postData, match, style }) => {
  const [date, setDate] = useState(null)
  const [excerpt, setExcerpt] = useState(null)

  const excerptRef = useRef(null)
  const headingRef = useRef(null)

  useEffect(() => {
    const monthMap = [
      "ERR",
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ]
    const dateArr = postData.date.split(/[-T]/)
    const dateObj = {
      year: dateArr[0],
      month: monthMap[parseInt(dateArr[1])],
      day: dateArr[2],
      gmt: dateArr[3],
    }
    setDate(dateObj)

    const content = postData.content.rendered
    const pOpen = content.search("<p>")
    const pClose = content.search("</p>")
    setExcerpt(content.slice(pOpen, pClose + 4)) //4 is '</p>'.length
  }, [postData])

  useEffect(() => {
    if (excerptRef.current && headingRef) {
      excerptRef.current.innerHTML = excerpt
      headingRef.current.innerHTML = postData.title.rendered
    }
  })

  if (date)
    return (
      <li className={classes.article} style={style}>
        <time className={classes.date} dateTime={postData.date}>
          <div className={classes.month}>{date.month}</div>
          <div className={classes.day}>{date.day}</div>
        </time>
        <div className={classes.text}>
          <h2 ref={headingRef}>&nbsp;</h2>
          <p ref={excerptRef}></p>
        </div>
        <div className={classes.linkContainer}>
          <div className={classes.spacer}></div>
          <Link to={match.url + "/" + postData.slug}>
            <button>READ MORE</button>
          </Link>
        </div>
      </li>
    )
  else return <></>
}

export default withRouter(ArticleLink)
