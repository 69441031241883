import React, { useState } from "react";
import hero3000 from "../../res/img/Hero/EGN_Hero_3000.jpg";
import hero2000 from "../../res/img/Hero/EGN_Hero_2000.jpg";
import hero1700 from "../../res/img/Hero/EGN_Hero_1700.jpg";
import hero1400 from "../../res/img/Hero/EGN_Hero_1400.jpg";
import hero800 from "../../res/img/Hero/EGN_Hero_800.jpg";
import Flourish from "../../res/Flourish";
import PineCircle from "../General/PineCircle/PineCircle";
import classnames from "classnames";

import classes from "./Header.module.scss";

const Header = (props) => {
  const [loaded, setLoaded] = useState(false);

  function transitionIn() {
    setLoaded(true);
    if (props.loaded) props.loaded();
  }

  return (
    <div className={classnames(classes.header, { [classes.loaded]: loaded })}>
      <img
        className={classes.hero}
        srcSet={
          hero800 +
          " 800w," +
          hero1400 +
          " 1400w," +
          hero1700 +
          " 1700w," +
          hero2000 +
          " 2000w," +
          hero3000 +
          " 3000w"
        }
        sizes="
        (max-width:500px) 800px,
        (max-width:900px) 1400px,
        (max-width:1600px) 1700px,
        3000px"
        src={hero2000}
        alt="treeline_hero-img"
        onLoad={transitionIn}
        onAnimationStart={transitionIn}
      />
      <div className={classes.headerContent}>
        <div className={classes.circle}>
          {/* <img className={classes.pinecone} src={pinecone} alt="Pinecone" /> */}
          <PineCircle alpha={0.8} />
        </div>
        <div className={classes.top}>
          <h1>Elizabeth Gordon</h1>
          <h2>Connected Apothecary</h2>
        </div>
      </div>
    </div>
  );
};

export default Header;
