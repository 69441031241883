import React from 'react';

const IgPink = (props) => {
    return (
        <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Element / Social Pink Share</title>
        <desc>Created with Sketch.</desc>
        <defs>
            <polygon id="path-1" points="0 0.0222297297 25.9309333 0.0222297297 25.9309333 20.9526081 0 20.9526081"></polygon>
            <polygon id="path-3" points="0.00706236559 0.0765677419 23.9305806 0.0765677419 23.9305806 24 0.00706236559 24"></polygon>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Articles-Post" transform="translate(-887.000000, -4082.000000)">
                <g id="Element-/-Social-Pink-Share" transform="translate(886.000000, 4080.000000)">
                    <g id="Connect" transform="translate(1.000000, 2.000000)">
                        <g id="social-icons">
                        <g id="tw" transform="translate(0.000000, 3.000000)">
                            <g id="Clip-2"></g>
                            <path d="M23.2776,5.2342973 C23.2861714,5.46255405 23.2922667,5.69052703 23.2922667,5.91887838 C23.2922667,12.9024189 17.941219,20.9526081 8.15655238,20.9526081 C5.1503619,20.9526081 2.35388571,20.0774189 -1.9047619e-05,18.5794189 C0.416361905,18.6275676 0.839219048,18.6535811 1.26817143,18.6535811 C3.76217143,18.6535811 6.05569524,17.808473 7.87531429,16.3916351 C5.5476,16.3475541 3.58321905,14.8208919 2.9063619,12.7211757 C3.23093333,12.7833243 3.56521905,12.8172838 3.90740952,12.8172838 C4.39179048,12.8172838 4.86302857,12.7512568 5.30864762,12.631027 C2.87607619,12.145473 1.04179048,10.0093378 1.04179048,7.44960811 L1.04179048,7.38301351 C1.75912381,7.77832432 2.57969524,8.01736486 3.45074286,8.04413514 C2.02426667,7.09743243 1.08521905,5.47882432 1.08521905,3.64624324 C1.08521905,2.67778378 1.34779048,1.77052703 1.80502857,0.988702703 C4.4296,4.18722973 8.34912381,6.29063514 12.7706476,6.51018919 C12.679981,6.12367568 12.6330286,5.72136486 12.6330286,5.30637838 C12.6330286,2.38860811 15.015219,0.0222297297 17.9533143,0.0222297297 C19.4831238,0.0222297297 20.8662667,0.663675676 21.8376,1.69144595 C23.0497905,1.45439189 24.187981,1.01414865 25.2146476,0.408459459 C24.8184571,1.64282432 23.9736952,2.67778378 22.8754095,3.33190541 C23.9510286,3.20439189 24.9767429,2.92022973 25.9309333,2.49975676 C25.2161714,3.55987838 24.3150286,4.49059459 23.2776,5.2342973" id="Fill-1" fill="#ECC0C6" mask="url(#mask-2)"></path>
                        </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
}

export default IgPink;