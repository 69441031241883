import React, { useEffect, useState } from "react"
import classes from "./Product.module.scss"
import { Link } from "react-router-dom"
import slug from "slugify"
import Loading from "../../../General/Loading/Loading"

const Product = (props) => {
  const [loading, setLoading] = useState(true)

  const [product, setProduct] = useState({
    product: "",
    imgSrc: "",
    color: "",
    desc: "",
    PDFLink: "",
  })

  const [nextProduct, setNextProduct] = useState("")
  const [previousProduct, setPreviousProduct] = useState("")

  useEffect(() => {
    setLoading(true)
    const slug = props.location.pathname.split("/")[2]

    const KEY = "247c6928aeab938190b6fb37f5a05b87"
    const PW = "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"

    var headers = new Headers()

    headers.append("Authorization", "Basic " + btoa(KEY + ":" + PW))

    //TODO: UNSAFE OPERATION!! FIX BEFORE PRODUCTION

    fetch(
      `https://cors-anywhere.herokuapp.com/https://shop.elizabethgordon.com.au/admin/api/2020-10/products.json?status=active`,
      { headers: headers }
    )
      .then((res) => {
        if (!res.ok) throw new Error("woopsie daisies")
        setLoading(false)
        return res.json()
      })
      .then((json) => {
        let filtered = json.products.filter((p) => !p.tags.includes("coming"))
        const productData = filtered.find((prod, i) => {
          if (prod.handle === slug) {
            const nextInd = i + 1 > filtered.length - 1 ? 0 : i + 1
            const prevInd = i - 1 < 0 ? filtered.length - 1 : i - 1
            setNextProduct(filtered[nextInd].title)
            setPreviousProduct(filtered[prevInd].title)
          }
          return prod.handle === slug
        })

        if (!productData) {
          setProduct(null)
          return
        }

        const tags = productData.tags.split(/[\s,]/g)
        const productObj = {
          product: productData.title,
          slug: productData.handle,
          imgSrc: productData.image.src,
          desc:
            `<div class=${classes.figure}><img src=${productData.image.src} alt=${productData.title} /><a href=https://shop.elizabethgordon.com.au/products/${productData.handle}><span class=${classes.buyNow}>BUY NOW</span></a></div><div class=${classes.spacer}></div>` +
            productData.body_html,
          col: tags.filter((t) => t.includes("#"))[0],
          PDFLink: tags.filter((t) => t.includes(".pdf"))[0],
        }

        setProduct(productObj)
      })
      .catch((error) => console.error("there is an error: " + error))
  }, [props.location.pathname])

  return (
    <>
      {loading && <Loading subject="ESSENCE" />}
      {!loading && (
        <div className={classes.product}>
          <Link to="/essences">
            <span className={classes.viewAll}>View All Essences</span>
          </Link>
          {!product && <h1 className={classes.error}>PRODUCT NOT FOUND</h1>}
          {product && (
            <>
              <div className={classes.nav}>
                <Link to={"/essences/" + slug(previousProduct.toLowerCase())}>
                  <div className={classes.back}>{previousProduct}</div>
                </Link>
                <Link to={"/essences/" + slug(nextProduct.toLowerCase())}>
                  <div className={classes.forward}>{nextProduct}</div>
                </Link>
              </div>
              <h1
                className={classes.productName}
                style={{ backgroundColor: product.col }}
              >
                {product.product}
              </h1>
              <p
                className={classes.desc}
                dangerouslySetInnerHTML={{ __html: product.desc }}
              ></p>
              <div className={classes.nav}>
                <Link to={"/essences/" + slug(previousProduct.toLowerCase())}>
                  <div className={classes.back}>{previousProduct}</div>
                </Link>
                <Link to={"/essences/" + slug(nextProduct.toLowerCase())}>
                  <div className={classes.forward}>{nextProduct}</div>
                </Link>
              </div>
              <a
                href={product.PDFLink}
                rel="noreferrer noopener"
                target="_blank"
              >
                <span className={classes.pdf}>View Product PDF</span>
              </a>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default Product
