import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import Nav from "../General/Nav/Nav"
import ArticlesHeader from "../Layout/SecondaryLayout/SecondaryHeader/SecondaryHeader"
import Header from "../Header/Header"
import ArticleLink from "./ArticleLink/ArticleLink"
import Article from "./Article/Article"
import Footer from "../Footer/Footer"
import classes from "./Articles.module.scss"
import Flourish from "../../res/Flourish"
import TreeLine from "../General/TreeLine/TreeLine.js"
import classnames from "classnames"
import useWindowSize from "../../Hooks/useWindowSize"

const Articles = ({ match }) => {
  const [posts, setPosts] = useState([])
  const [articlesState, setArticleState] = useState("loading")
  const [intro, setIntro] = useState(true)
  const [waiting, setWaiting] = useState(false)
  const [firstLoadComplete, setFirstLoadComplete] = useState(false)

  const POSTS_PER_LOAD = 4
  const HEADER_BP = 550
  const INTRO_TIME = 3000

  useEffect(() => {
    if (articlesState !== "loading" || waiting) return () => null

    setArticleState("loading")
    const currentPage = Math.ceil(posts.length / POSTS_PER_LOAD)
    let totalPosts = 0

    fetch(
      `https://liz.queued.com.au/wp-json/wp/v2/posts?per_page=${POSTS_PER_LOAD}&page=${
        currentPage + 1
      }`
    )
      .then((res) => {
        totalPosts = Number(res.headers.get("X-WP-Total"))
        if (!res.ok) {
          throw new Error(
            "WP Posts could not be retrieved:. Status: " + res.status
          )
        }
        return res.json()
      })
      .then((postsArr) => {
        let loadedPosts = 0
        setPosts((prev) => {
          const newPosts = prev.concat(postsArr)
          loadedPosts = newPosts.length
          return newPosts
        })
        setArticleState(
          totalPosts === loadedPosts ? "no more articles" : "load more"
        )
        setWaiting(false)
        setFirstLoadComplete(true)
      })
      .catch((e) => {
        console.error(e)
        setArticleState("ERROR")
      })

    setWaiting(true)
  }, [articlesState, posts.length, waiting])

  useEffect(() => {
    setTimeout(() => setIntro(false), INTRO_TIME)
    window.document.title = "Elizabeth Gordon | Articles"
    setTimeout(() => window.scrollTo(0, 0), 100)
  }, [])

  function loadClickedHandler() {
    if (articlesState === "load more") {
      setArticleState("loading")
    }
  }

  return (
    <>
      <Switch>
        <Route path={match.url + "/:article"} component={Article} />
        <Route>
          <div className={classes.articlesPage}>
            <Nav hamburgerColor="var(--white)" />
            {useWindowSize().width > HEADER_BP ? (
              <ArticlesHeader />
            ) : (
              <Header />
            )}
            <div className={classes.articles}>
              {(intro || !firstLoadComplete) && articlesState !== "ERROR" ? (
                <div className={classes.head}>
                  <h1 className={classes.loading}>LOADING ARTICLES</h1>
                  <div className={classes.animate1}>
                    <div className={classes.animate2}>
                      <div className={classes.animate3}>
                        <Flourish height="3.3rem" color="currentColor" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.head + " " + classes.loaded}>
                  <h1>ARTICLES</h1>
                  <Flourish height="3.3rem" color="currentColor" />
                </div>
              )}
              {!intro && firstLoadComplete && (
                <>
                  <ul className={classes.list}>
                    {posts.map((p, i) => (
                      <ArticleLink
                        postData={p}
                        key={p.id}
                        style={{ animationDelay: (i % 4) * 0.2 + "s" }}
                      />
                    ))}
                    {/* {posts.map((p, i) => <ArticleLink postData={p} key={p.id} style={{color: 'blue'}}/>)} */}
                  </ul>
                  <button
                    className={classnames(
                      classes.articleState,
                      { [classes.loading]: articlesState === "loading" },
                      {
                        [classes.disabled]:
                          articlesState === "no more articles",
                      }
                    )}
                    onClick={loadClickedHandler}
                  >
                    {articlesState.toUpperCase()}
                  </button>
                </>
              )}
              {!intro && articlesState === "ERROR" && (
                <>
                  <h2 className={classes.error}>
                    Woops!.. Articles could not be loaded.
                    <br />
                    <br />
                    Please try again.
                  </h2>
                </>
              )}
            </div>
          </div>
          <TreeLine />
          <Footer />
        </Route>
      </Switch>
    </>
  )
}

export default Articles
