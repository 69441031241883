import React from "react"
import PineCircle from "../../../General/PineCircle/PineCircle"
import classes from "./SecondaryHeader.module.scss"

const ArticlesHeader = (props) => {
  return (
    <header className={classes.header}>
      <div className={classes.content}>
        <div className={classes.text}>
          <h1>Elizabeth Gordon</h1>
          <h2>Connected Apothecary</h2>
        </div>
        <div className={classes.circle}>
          <div className={classes.innerCircle}>
            <PineCircle />
          </div>
        </div>
      </div>
    </header>
  )
}

export default ArticlesHeader
