import React from "react"
import Flourish from "../../../res/Flourish"
import ThresholdReveal from "../../HOCs/ThresholdReveal/ThresholdReveal"
import classes from "./Intro.module.scss"

const Intro = (props) => {
  return (
    <>
      <ThresholdReveal>
        <h1>WHAT IS CONNECTED APOTHECARY?</h1>
      </ThresholdReveal>
      <ThresholdReveal>
        <p>
          Connected Apothecary is a concept as old as time itself. The idea that
          through the universal connection we are all attuned with, we have an
          innate ability to guide and be guided to a healthier and more peaceful
          state of Being.
        </p>
      </ThresholdReveal>
      <ThresholdReveal>
        <p>
          As in the truest sense of the word ‘Apothecary’, the service that is
          provided to a larger audience is aligned to the service of dispensing
          &amp; delivering balance to both the physical and non physical bodies
          via modalities that have been formulated over decades of learning and
          experience.
        </p>
        <div className={classes.flourishContainer}>
          <Flourish height="3.3rem" />
        </div>
      </ThresholdReveal>
    </>
  )
}

export default Intro
