import React from "react"
import ThresholdReveal from "../../HOCs/ThresholdReveal/ThresholdReveal"
import EG from "../../../res/img/EGN_Profile_med.png"

import classes from "./About.module.scss"

const About = (props) => {
  return (
    <>
      <ThresholdReveal>
        <h1 className={classes.head}>ABOUT ELIZABETH</h1>
      </ThresholdReveal>
      <ThresholdReveal>
        <p>
          Elizabeth has been working with alternative health since mid ‘80’s
          studying many applications along the way to gain experience and to
          expand knowledge.
        </p>
      </ThresholdReveal>
      <ThresholdReveal>
        <p>
          The tools that Elizabeth uses to trust in the Higher Knowing are
          primarily Alchemy and Vibrational Medicine. Additionally the Bowen
          Technique is still highly valued which she learnt and taught 30 years
          ago, both in Australia &amp; overseas. All of these skills have
          resonated highly with her and have subsequently become embraced for
          many years.
        </p>
      </ThresholdReveal>
      <ThresholdReveal>
        <p>
          Whilst living in Texas, she gained further knowledge into the
          energetic structure connected to the earth and what influences us on a
          daily basis. In gaining the understanding behind our conscious and
          sub-conscious attachments, belief systems and how we lose our personal
          power, Elizabeth’s knowledge is ever expanding.
        </p>
      </ThresholdReveal>
      <img className={classes.eg} src={EG} alt="Elizabeth_Gordon_profile" />
    </>
  )
}

export default About
