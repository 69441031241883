import React from "react"
import pinecone from "../../../res/img/EGN_Cone_Large.png"
import classes from "./PineCircle.module.scss"

const PineCircle = ({ alpha = 1 }) => {
  return (
    <div
      className={classes.circle}
      style={{ backgroundColor: `rgba(255,255,255,${alpha})` }}
    >
      <img className={classes.pinecone} src={pinecone} alt="pinecone" />
    </div>
  )
}

export default PineCircle
