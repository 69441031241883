import React, { useState, useEffect } from "react"
import { Link, Route } from "react-router-dom"
import Layout from "../Layout/SecondaryLayout/SecondaryLayout"
import Section from "../Layout/Section/Section"
import Flourish from "../../res/Flourish"
import Video from "./VideoModal/VideoModal"
import Loading from "../General/Loading/Loading"

import { BROWSER_KEY } from "../../sensitive/keys"

import classes from "./Videos.module.scss"

const Videos = (props) => {
  const RESULTS_PER_PAGE = 8

  const [videosData, setVideosData] = useState([])
  const [firstLoad, setFirstLoad] = useState(false)

  useEffect(() => {
    fetch(
      `https://www.googleapis.com/youtube/v3/search?channelId=UCBKCVgKCta_kZuUq-9C07hA&key=${BROWSER_KEY}&maxResults=${RESULTS_PER_PAGE}&part=snippet&order=date`
    )
      .then((res) => {
        setFirstLoad(true)
        return res.json()
      })
      .then((json) => {
        if (json.error) throw json.error.message
        const videos = []
        for (let i = 0; i < json.items.length; i++) {
          const item = json.items[i]
          if (item.id.videoId) {
            videos.push({
              id: item.id.videoId,
              title: item.snippet.title,
              date: item.snippet.publishTime,
            })
          }
        }
        setVideosData(videos)
      })
      .catch((error) => {
        console.error(error)
        setVideosData(null)
      })
  }, [])

  function convertDate(date) {
    if (!date) return ""
    const a = date.split(/[-T]/g)
    return a[2] + "." + a[1] + "." + a[0]
  }

  // function convertDuration(dur) {
  //   if (!dur) return ""
  //   let s = parseDur(dur) / 1000
  //   let a = []
  //   a.push(Math.floor(s / 3600))
  //   a.push(s / 60 < 10 ? "0" + Math.floor(s / 60) : Math.floor(s / 60))
  //   a.push(s % 60 < 10 ? "0" + Math.floor(s % 60) : Math.floor(s % 60))
  //   return a[0] ? a[0] + ":" + a[1] + ":" + a[2] : a[1] + ":" + a[2]
  // }

  return (
    <>
      <Layout>
        <Section>
          <div className={classes.videos}>
            <h1>VIDEOS</h1>
            <div className={classes.flourishContainer}>
              <Flourish height="3.3rem" color="currentcolor" />
            </div>
            <div className={classes.divider}></div>
            {!firstLoad && <Loading />}
            {Array.isArray(videosData) && (
              <div className={classes.listContainer}>
                {videosData[0] &&
                  videosData.map((data) => (
                    <div className={classes.videoContainer} key={data.id}>
                      <Link to={"/videos/" + data.id}>
                        <div className={classes.titleContainer}>
                          <h2
                            className={classes.vidTitle}
                            dangerouslySetInnerHTML={{ __html: data.title }}
                          ></h2>
                        </div>
                        <div className={classes.thumb}>
                          <img
                            src={`https://img.youtube.com/vi/${data.id}/0.jpg`}
                            alt=""
                          />
                        </div>
                      </Link>
                      <div className={classes.sub}>
                        <span>{convertDate(data.date)}</span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {!Array.isArray(videosData) && (
              <h2 className={classes.error}>CAN NOT LOAD VIDEOS</h2>
            )}
          </div>
        </Section>
      </Layout>
      <Route path="/videos/:id" exact component={Video} />
    </>
  )
}

export default Videos
