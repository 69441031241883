import React from "react"
import classNames from "classnames"

import classes from "./STDButton.module.scss"

const STDButton = ({
  children,
  theme = "dark",
  type = "button",
  style = {},
  clicked,
  disabled = false,
}) => {
  if (type === "button")
    return (
      <button
        className={classNames(classes.button, classes[theme])}
        style={style}
        onClick={clicked}
      >
        {children}
      </button>
    )
  else if (type === "submit")
    return (
      <input
        type="submit"
        className={classNames(classes.button, classes[theme], {
          [classes.disabled]: disabled,
        })}
        value={children}
        style={style}
        disabled={disabled}
      />
    )
}

export default STDButton
