export const lockUnderScroll = () => {
  const top = window.scrollY
  document.body.style.position = 'fixed'
  document.body.style.top = `-${top}px`
  document.body.style.overscrollBehaviourY = `contain`
  //alert('lock');
}

export const unlockUnderScroll = () => {
  const scrollY = document.body.style.top
  document.body.style.position = ''
  document.body.style.top = ''
  document.body.style.width = ''
  document.body.style.overscrollBehaviourY = ``
  window.scrollTo(0, parseInt(scrollY || '0') * -1)
  //alert('unlock');
}

