import React from "react"
import ThresholdReveal from "../../HOCs/ThresholdReveal/ThresholdReveal"
import Button from "../../General/Buttons/STDButton/STDButton"
import { Link } from "react-router-dom"

import img1 from "../../../res/img/products/EGN-04.2008_LabelMock_v3.21.png"
import img2 from "../../../res/img/products/EGN-04.2008_LabelMock_v3.11.png"

import classes from "./Availability.module.scss"

const Availability = (props) => {
  return (
    <>
      <ThresholdReveal>
        <h1>AVAILABILITY</h1>
      </ThresholdReveal>
      <div className={classes.divider}>
        <ThresholdReveal>
          <p>
            If you would like a harmonic sound bed session or consultation with Libby, please get in touch directly via the form at the bottom of this page or via phone to discuss what will be the best approach and time.
          </p>
        </ThresholdReveal>
      </div>
    </>
  )
}

export default Availability
