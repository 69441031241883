

import React from 'react';

const FbPink = (props) => {
  return (
    <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Element / Social Pink Share</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <polygon id="path-1" points="0 0.0222297297 25.9309333 0.0222297297 25.9309333 20.9526081 0 20.9526081"></polygon>
        <polygon id="path-3" points="0.00706236559 0.0765677419 23.9305806 0.0765677419 23.9305806 24 0.00706236559 24"></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Articles-Post" transform="translate(-887.000000, -4082.000000)">
            <g id="Element-/-Social-Pink-Share" transform="translate(886.000000, 4080.000000)">
                <g id="Connect" transform="translate(1.000000, 2.000000)">
                    <g id="social-icons">
                        <g id="fb" transform="translate(6.000000, 0.000000)" fill="#ECC0C6">
                            <path d="M12.6818856,4.05883999 L12.6818856,0.175387888 L8.87264165,0.175387888 C6.17680448,0.175387888 3.99110394,2.31333911 3.99110394,4.95129505 L3.99110394,8.5785871 L0.347150989,8.5785871 L0.347150989,12.8971829 L3.99110394,12.8971829 L3.99110394,23.4769215 L8.40529609,23.4769215 L8.40529609,12.8971829 L12.0316292,12.8971829 L12.6130843,8.5785871 L8.40529609,8.5785871 L8.40529609,5.57691672 C8.40529609,4.73864936 9.09918259,4.05883999 9.95584296,4.05883999 L12.6818856,4.05883999 Z" id="facebook"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
  )
}

export default FbPink;