import React from "react"
import Flourish from "../../../res/Flourish"

import classes from "./Loading.module.scss"

const Loading = (props) => {
  return (
    <div className={classes.container}>
      <span className={classes.subject}>LOADING {props.subject}</span>
      <div className={classes.flourishContainer}>
        <Flourish height="3.3rem" color="currentcolor" />
      </div>
    </div>
  )
}

export default Loading
