import easyScroll from "easy-scroll"

const goToRef = (ref) => {
  function scrollToSection(ref) {
    easyScroll({
      scrollableDomEle: window,
      direction: "bottom",
      duration: 2000,
      easingPreset: "easeInOutQuint",
      scrollAmount: ref.current.getBoundingClientRect().y,
    })
  }
  scrollToSection(ref)
}

export default goToRef
