import React from 'react';

const InstagramSVG = ({height = "auto", width = "auto", color = "#000"}) => {
  return (
<svg style={{ height, width}} viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <polygon id="path-1" points="24.5001828 0.152052096 0 0.152052096 0 25.0328743 24.5001828 25.0328743"></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menu-&amp;-Pop-Up" transform="translate(-493.000000, -533.000000)">
            <g id="Element-/-Social-White" transform="translate(492.000000, 531.000000)">
                <g id="Connect" transform="translate(1.000000, 2.000000)">
                    <g id="social-icons">
                        <g id="ig" transform="translate(0.900000, 0.000000)">
                            <g id="Group-3">
                                <g id="Clip-2"></g>
                                <path d="M22.0356393,17.6207982 C22.0356393,20.3322394 19.8714606,22.5300412 17.2015004,22.5300412 L7.29859111,22.5300412 C4.62908741,22.5300412 2.46445222,20.3322394 2.46445222,17.6207982 L2.46445222,7.56403549 C2.46445222,4.85305788 4.62908741,2.65479251 7.29859111,2.65479251 L17.2015004,2.65479251 C19.8714606,2.65479251 22.0356393,4.85305788 22.0356393,7.56403549 L22.0356393,17.6207982 Z M17.2015004,0.151959381 L7.29859111,0.151959381 C3.27425037,0.151959381 -9.12962963e-05,3.47717184 -9.12962963e-05,7.56403549 L-9.12962963e-05,17.6207982 C-9.12962963e-05,21.7076619 3.27425037,25.0328743 7.29859111,25.0328743 L17.2015004,25.0328743 C21.2262976,25.0328743 24.5001828,21.7076619 24.5001828,17.6207982 L24.5001828,7.56403549 C24.5001828,3.47717184 21.2262976,0.151959381 17.2015004,0.151959381 L17.2015004,0.151959381 Z" id="Fill-1" fill={color} mask="url(#mask-2)"></path>
                            </g>
                            <path d="M12.325235,16.5656291 C10.1230505,16.5656291 8.33827036,14.7531203 8.33827036,12.5167222 C8.33827036,10.2803241 10.1230505,8.4678153 12.325235,8.4678153 C14.5274196,8.4678153 16.3121997,10.2803241 16.3121997,12.5167222 C16.3121997,14.752643 14.5269495,16.5656291 12.325235,16.5656291 M12.325235,5.89010989 C8.72747182,5.89010989 5.8,8.86306346 5.8,12.5167222 C5.8,16.1703809 8.72747182,19.1428571 12.325235,19.1428571 C15.9229982,19.1428571 18.85,16.1703809 18.85,12.5167222 C18.85,8.86306346 15.9229982,5.89010989 12.325235,5.89010989" id="Fill-4" fill={color}></path>
                            <path d="M18.85,4.41758242 C18.0491431,4.41758242 17.4,5.07681074 17.4,5.89010989 C17.4,6.70340904 18.0491431,7.36263736 18.85,7.36263736 C19.6508569,7.36263736 20.3,6.70340904 20.3,5.89010989 C20.3,5.07681074 19.6508569,4.41758242 18.85,4.41758242" id="Fill-6" fill={color}></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
  
  )
}

export default InstagramSVG;
