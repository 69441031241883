import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import ArticlesHeader from "../../Layout/SecondaryLayout/SecondaryHeader/SecondaryHeader"
import Nav from "../../General/Nav/Nav"
import Footer from "../../Footer/Footer"
import Flourish from "../../../res/Flourish"
import classnames from "classnames"
import easyScroll from "easy-scroll"
import FB from "../../../res/svg/socials/FbPink"
import TW from "../../../res/svg/socials/TwPink"
import LI from "../../../res/svg/socials/LiPink"

import classes from "./Article.module.scss"
import "./Article.css"

const Article = (props) => {
  const [postObj, setPostObj] = useState({})
  const [date, setDate] = useState({})
  const [showBtn, setShowBtn] = useState(false)
  const [winHeight, setWinHeight] = useState(null)
  const [timeoutID, setTimeoutID] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [heroImg, setHeroImg] = useState({ src: "", alt: "" })

  const titleRef = useRef(null)
  const articleRef = useRef(null)
  const btnRef = useRef(null)
  const showRef = useRef(showBtn)
  const heightRef = useRef(winHeight)
  const IDRef = useRef(timeoutID)

  const updateShow = (show) => {
    showRef.current = show
    setShowBtn(show)
  }

  const updateHeight = (h) => {
    heightRef.current = h
    setWinHeight(h)
  }

  const updateID = (ID) => {
    IDRef.current = ID
    setTimeoutID(ID)
  }

  useEffect(() => {
    fetch(
      `https://liz.queued.com.au/wp-json/wp/v2/posts?slug=${props.match.params.article}&_embed`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            "Post could not be retrieved from Wordpress. Status: " + res.status
          )
        }
        return res.json()
      })
      .then((postArr) => {
        const post = postArr[0]
        setPostObj(post)
        const monthMap = [
          "ERR",
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ]
        const dateArr = post.date.split(/[-T]/)
        const dateObj = {
          year: dateArr[0],
          month: monthMap[parseInt(dateArr[1])],
          day: dateArr[2],
          gmt: dateArr[3],
        }
        setDate(dateObj)
      })
      .catch((e) => {
        setPostObj(null)
        console.error(e)
      })

    function throttle(fn, ms) {
      if (IDRef.current) {
        return
      }

      const to = setTimeout(() => {
        fn()
        updateID(null)
      }, ms)

      updateID(to)
    }

    function handleScroll() {
      if (btnRef.current && articleRef.current) {
        const PADDING = 70

        const articleBox = articleRef.current.getBoundingClientRect()

        if (
          heightRef.current + articleBox.y - PADDING < 0 &&
          !showRef.current
        ) {
          updateShow(true)
        } else if (
          heightRef.current + articleBox.y - PADDING > 0 &&
          showRef.current
        ) {
          updateShow(false)
        }
      }
    }

    const throttledScrollHandler = () => {
      throttle(handleScroll, 20)
    }

    window.addEventListener("scroll", throttledScrollHandler)
    const body = document.getElementsByTagName("body")[0]
    updateHeight(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        body.clientHeight
    )
    return () => window.removeEventListener("scroll", throttledScrollHandler)
  }, [props.match.params.article])

  useEffect(() => {
    if (postObj && postObj._embedded && postObj._embedded["wp:featuredmedia"]) {
      let obj = {}
      obj.src = postObj._embedded["wp:featuredmedia"][0].source_url
      obj.alt = postObj._embedded["wp:featuredmedia"][0].alt_text
      setHeroImg(obj)
    }
  }, [postObj])

  function goToTop() {
    easyScroll({
      scrollableDomEle: window,
      direction: "top",
      duration: 1500,
      easingPreset: "easeInOutQuint",
    })
  }

  useEffect(() => {
    if (articleRef && postObj && postObj.content) {
      titleRef.current.innerHTML = postObj.title.rendered
      articleRef.current.innerHTML = postObj.content.rendered
    }
  }, [postObj])

  const [lock, setLock] = useState(false)

  const footRef = useRef(null)

  function onFootRefUpdate(f) {
    if (!footRef.current) {
      footRef.current = f

      const intersectionOptions = {
        root: null,
      }

      const Observer = new IntersectionObserver((e) => {
        if (e[0].boundingClientRect.bottom <= 0) return
        setLock(e[0].isIntersecting)
      }, intersectionOptions)

      Observer.observe(f)
    }
  }

  return (
    <div className={classes.articlePage}>
      <ArticlesHeader />
      <div className={classes.heroContainer + " " + classes.test}>
        {heroImg.src && (
          <img
            className={classnames(
              classes.hero,
              { [classes.appear]: loaded },
              classes.test
            )}
            src={heroImg.src}
            alt={heroImg.alt}
            onLoad={() => setLoaded(true)}
          />
        )}
      </div>
      <div className={classes.article}>
        <div className={classes.articleContent}>
          <Nav hamburgerColor="var(--white)" />
          <Link to="/articles">
            <button className={classes.goBack}>
              <span></span>Back to Articles
            </button>
          </Link>
          {postObj && postObj.date ? (
            <>
              <div className={classes.header}>
                <h1 ref={titleRef}>&nbsp;</h1>
                <time className={classes.date} dateTime={postObj.date}>
                  <h2>
                    {date.month + " " + date.day}
                    <br />
                    {date.year}
                  </h2>
                </time>
              </div>
              <div className={classes.articleOuter}>
                <div className={classes.articleMain} ref={articleRef}></div>
              </div>
              <div
                className={classnames(classes.buttonWrapper, {
                  [classes.hide]: !showBtn,
                })}
              >
                <div
                  className={classnames(classes.buttonContainer, {
                    [classes.stuck]: lock,
                  })}
                  ref={btnRef}
                >
                  <button className={classes.button} onClick={goToTop}></button>
                </div>
              </div>
              <div className={classes.foot} ref={onFootRefUpdate}>
                <p>
                  We love hearing feedback, so if you would like to begin a
                  conversation with Elizabeth, please use the form in the footer
                  below. She will reply to your message as soon as she is able.
                  <br />
                  <br />
                  Also, please feel free to share this article on any of the
                  platforms below.
                </p>
                <div className={classes.socialsOuter}>
                  <div className={classes.socialsContainer}>
                    <a
                      href={`http://twitter.com/share/?url=${encodeURI(
                        window.location.href
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TW />
                    </a>
                    <a
                      href={`https://www.facebook.com/dialog/feed?app_id=701441013939746&display=popup&link=${encodeURI(
                        window.location.href
                      )}&redirect_uri=${encodeURI(window.location.href)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FB />
                    </a>
                    <a
                      href={`http://www.linkedin.com/shareArticle/?mini=true&url=${encodeURI(
                        window.location.href
                      )}&title=${encodeURI(
                        props.match.params.article.replace("-", "%20")
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LI />
                    </a>
                  </div>
                </div>
                <h2>SHARE</h2>
                <div className={classes.flourishContainer}>
                  <Flourish color="currentColor" height="3.5rem" />
                </div>
              </div>
            </>
          ) : (
            <>
              {postObj ? (
                <div className={classes.head}>
                  <h1 className={classes.loading}>LOADING POST</h1>
                  <div className={classes.animate1}>
                    <div className={classes.animate2}>
                      <div className={classes.animate3}>
                        <Flourish height="3.3rem" color="currentColor" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.errorContainer}>
                  <h2 className={classes.error}>404: Article not found</h2>
                  <Flourish height="3.3rem" color="currentColor" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Article
