import React from "react"
import { Route, Switch } from "react-router-dom"
import LandingPage from "./LandingPage/LandingPage/LandingPage"
import Articles from "./Articles/Articles"
import FAQ from "./FAQ/FAQ"
import Videos from "./Videos/Videos"

import Err404 from "./General/Err404/Err404"

const App = (props) => {
  return (
    <Switch>
      <Route path="/articles" component={Articles} />
      <Route path="/" exact component={LandingPage} />
      <Route path="/pricing" exact component={LandingPage} />
      <Route path="/soundbed" exact component={LandingPage} />
      <Route path="/essences" component={LandingPage} />
      <Route path="/testimonials" component={LandingPage} />
      <Route path="/faq" exact component={FAQ} />
      <Route path="/videos" component={Videos} />
      <Route path="*" component={Err404} />
    </Switch>
  )
}

export default App
