import React, { useEffect, useState } from "react"
import Layout from "../Layout/SecondaryLayout/SecondaryLayout"
import Section from "../Layout/Section/Section"
import FAQItem from "./FAQItem/FAQItem"
import Flourish from "../../res/Flourish"
import Loading from "../General/Loading/Loading"
import Treeline from "../General/TreeLine/TreeLine"

import classes from "./FAQ.module.scss"

const FAQ = (props) => {
  const [FAQs, setFAQs] = useState([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    fetch("https://liz.queued.com.au/wp-json/wp/v2/pages?slug=faq")
      .then((res) => {
        if (res.ok) setLoaded(true)
        return res.json()
      })
      .then((json) => {
        let HTMLString = json[0].content.rendered
        HTMLString = HTMLString.replace(/<br>/g, "<br/>")
        const dom = new DOMParser().parseFromString(HTMLString, "text/xml")
        const qs = dom.querySelectorAll(".uagb-question")
        const as = dom.querySelectorAll(".uagb-faq-content p")
        const FAQArray = []
        for (let i = 0; i < qs.length; i++) {
          FAQArray[i] = {
            question: qs[i].innerHTML,
            answer: as[i].innerHTML,
          }
        }
        setFAQs(FAQArray)
      })
  }, [])

  return (
    <Layout>
      <Section>
        {loaded ? (
          <div className={classes.faq}>
            <h1>FAQ's</h1>
            <div className={classes.flourishContainer}>
              <Flourish height="30px" color="currentcolor" />
            </div>
            <div className={classes.faqContainer}>
              {FAQs.map((item) => (
                <FAQItem
                  question={item.question}
                  answer={item.answer}
                  key={item.question.replace(" ", "")}
                />
              ))}
            </div>
          </div>
        ) : (
          <Loading subject="FAQ" />
        )}
      </Section>
      <Treeline />
    </Layout>
  )
}

export default FAQ
