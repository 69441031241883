import React, {
  useRef,
  useState,
  useEffect,
  lazy,
  Suspense,
  useCallback,
} from "react"
import { Route } from "react-router-dom"

//General Components
import Nav from "../../General/Nav/Nav"
import Section from "../../Layout/Section/Section"

//Page Layout
import Header from "../../Header/Header"
import Intro from "../../LandingPage/Intro/Intro"
import About from "../../LandingPage/About/About"
import TreeLine from "../../General/TreeLine/TreeLine"
import Approach from "../../LandingPage/Approach/Approach"
import Services from "../../LandingPage/Services/Services"
import Details from "../../LandingPage/Details/Details"
import Products from "../Products/Products"
import Video from "../Video/Video"
import Testimonials from "../Testimonials/Testimonials"
import Footer from "../../Footer/Footer"

//Modals
import PricingModal from "../../Modals/PricingModal/PricingModal"
import ProductsModal from "../../Modals/ProductsModal/ProductsModal"
import TestimoniesModal from "../../Modals/TestimoniesModal/TestimoniesModal"
import SoundbedModal from "../../Modals/SoundbedModal/SoundbedModal"

//Helper Functions
import goToRef from "../../../Helper Functions/goToRef"

//Styling
import classes from "./LandingPage.module.scss"
import Consultation from "../Consultation/Consultation"
import Availability from "../Availability/Availability"

const Map = lazy(() => import("../../General/Map/Map"))

function LandingPage(props) {
  const SCROLL_DELAY = 1000

  const [introLoaded, setIntroLoaded] = useState(false)
  const [loadMap, setLoadMap] = useState(false)

  const introRef = useRef(null)
  const aboutRef = useRef(null)
  const approachRef = useRef(null)
  const servicesRef = useRef(null)
  const productsRef = useRef(null)
  const detailsRef = useRef(null)
  const footerRef = useRef(null)
  const loadMapRef = useRef(null)
  const consultationsRef = useRef(null)
  const avilabilityRef = useRef(null)

  const [refList] = useState({
    introRef,
    aboutRef,
    approachRef,
    servicesRef,
    detailsRef,
    footerRef,
    consultationsRef,
    avilabilityRef,
  })

  useEffect(() => {
    window.document.title = "Elizabeth Gordon | Connected Apothecary"

    const intersectionOptions = {
      root: null,
      threshold: 0.1,
    }

    const Observer = new IntersectionObserver((e, o) => {
      if (e[0].isIntersecting && !loadMapRef.current) {
        updateLoadMap(true)
      }
    }, intersectionOptions)

    // Observer.observe(detailsRef.current)
  }, [])

  const updateLoadMap = (load) => {
    loadMapRef.current = load
    setLoadMap(load)
  }

  useEffect(() => {
    const hash = props.location.hash.replace("#", "")
    if (hash === "pricing") {
      props.history.push("/pricing")
    } else if (refList[hash + "Ref"]) {
      setTimeout(() => goToRef(refList[hash + "Ref"]), SCROLL_DELAY)
      props.history.push("/")
    }
  }, [props.location.hash, refList, props.history])

  return (
    <div className={classes.LandingPage}>
      <Nav refs={refList} hamburgerColor="var(--white)" home />
      <Header loaded={() => setIntroLoaded(true)} />
      <Section color="var(--blush-white)" self={introRef}>
        <Intro />
      </Section>
      <TreeLine loadFull={introLoaded} />
      <Section color="var(--white)" self={aboutRef}>
        <About />
      </Section>
      <Section color="var(--pink-main)" self={approachRef}>
        <Approach />
      </Section>
      <Section color="var(--off-white)" self={servicesRef}>
        <Services />
      </Section>
      <Section color="var(--white)" self={consultationsRef}>
        <Consultation />
      </Section>
      <Section color="var(--white)" self={avilabilityRef}>
        <Availability />
      </Section>

      {/* <Section color="var(--white)" self={productsRef}>
        <Products />
      </Section>
      <Section color="var(--off-white)">
        <Video />
      </Section>
      <Section color="var(--grey-main)">
        <Testimonials />
      </Section> */}
      {/* <Section id="details" color="var(--white)" self={detailsRef}>
        <Details />
      </Section> */}
      {/* <Suspense fallback={<div className={classes.mapSpacer}></div>}>
        {loadMap && <Map />}
      </Suspense> */}

      <Footer refs={refList} home self={footerRef} />
      <Route exact path="/pricing" component={PricingModal} />
      <Route exact path="/soundbed" component={SoundbedModal} />
      <Route path="/essences" component={ProductsModal} />
      <Route path="/testimonials" component={TestimoniesModal} />
    </div>
  )
}

export default LandingPage
