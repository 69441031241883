import React from 'react';

const Cross = ({ color = '#000', width = 'auto', height = 'auto', clicked = null}) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" onClick={clicked}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menu-&amp;-Pop-Up" transform="translate(-492.000000, -54.000000)" fill={color}>
          <g id="X-Copy" transform="translate(492.000000, 54.000000)">
            <path d="M29.8180195,0.119534675 L33,3.30151519 L19.7405347,16.5595347 L33,29.8180195 L29.8180195,33 L16.5595347,19.7405347 L3.30151519,33 L0.119534675,29.8180195 L13.3775347,16.5595347 L0.119534675,3.30151519 L3.30151519,0.119534675 L16.5595347,13.3775347 L29.8180195,0.119534675 Z" id="Combined-Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Cross;
