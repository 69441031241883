import React, { useRef, useEffect, useState } from "react"
import classnames from "classnames"
import classes from "./ThresholdReveal.module.scss"

const ThresholdReveal = (props) => {
  const [revealed, setRevealed] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const checkPos = () => {
      const THRESHOLD = -100

      const win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        y = win.innerHeight || docElem.clientHeight || body.clientHeight

      if (ref.current.getBoundingClientRect().y - y < THRESHOLD) {
        setRevealed(true)
        window.removeEventListener("scroll", checkPos)
      }
    }

    window.addEventListener("scroll", checkPos)

    return () => window.removeEventListener("scroll", checkPos)
  }, [])

  return (
    <div
      className={classnames(classes.base, { [classes.hidden]: !revealed })}
      ref={ref}
    >
      {props.children}
    </div>
  )
}

export default ThresholdReveal
